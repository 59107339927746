import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'

export default class ArchiveBlog extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      hero : viewStorage.current.querySelector('.Archives__hero'),
      heroContent : viewStorage.current.querySelector('.Archives__hero .content'),
      heroVisuals : viewStorage.current.querySelectorAll('.Archives__hero .images .visual__container'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Blog`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { hero, heroContent, heroVisuals } = this.DOM

    //Apparition
    this.heroEntrance = gsap.timeline({
      defaults: { duration: 1.6, ease:'expo.out', delay: 0.3 }
    })

    const offsetHeights = [hero.offsetHeight * 1.2, -heroVisuals[0].offsetHeight * 1.2]
    const offsetHeightsEnd = [hero.offsetHeight * gsap.utils.random(0.4, 0.8), hero.offsetHeight * gsap.utils.random(-0.2, 0.3)]
    const rotates = [gsap.utils.random(-16, 16, 1), gsap.utils.random(-16, 16, 1)]
  
    gsap.set(heroVisuals, { 
      x: () => gsap.utils.random(-20, 20),
      y: (index) => offsetHeightsEnd[index],
      rotate: (index) => - rotates[index],
    })

    this.heroEntrance.from(heroVisuals, { y: (index) => offsetHeights[index], rotate: (index) => rotates[index], stagger: 0.3}, 0)
    this.heroEntrance.from(heroContent.querySelectorAll(':scope > *'), { opacity: 0, yPercent: 20, stagger: 0.3 }, 0.3)


    // Prllx
    this.HeroTL = gsap.timeline({ 
      paused: true, 
      defaults: { duration: 1, ease:'power0.none' },
      scrollTrigger : {
        trigger: hero,
        start: 'top 0',
        end: 'bottom top',
        scrub: true
      }
    })

    this.HeroTL.to(heroVisuals, { yPercent : 50, rotate: gsap.utils.random(-5, 5) }, 0)
    this.HeroTL.to(heroContent, { yPercent : 40 }, 0)
  }

  // onEnterCompleted() {}

  onLeave() {
    this.heroEntrance.kill(true)
    this.HeroTL.kill(true)
  }

  // onLeaveCompleted() {
  // }
}
