import { Renderer } from '@unseenco/taxi';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isDEVMODE, viewStorage } from '../_globals'

export default class FAQ extends Renderer {

  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      accordions : viewStorage.current.querySelectorAll('.FAQ__content .question'),
      categories : viewStorage.current.querySelectorAll('.faq-categories-filter'),
    }

    this.init()

    this.onCategoryFilterClick = this.onCategoryFilterClick.bind(this)
    this.initCategoryFilters()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : FAQ`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { accordions } = this.DOM
    accordions.length > 0 && this.initAccordion()
  }

  initAccordion() {
    const { accordions } = this.DOM
    this.onToggleAccordion = this.onToggleAccordion.bind(this)
    accordions.forEach(accordion => accordion.querySelector('.question__title').addEventListener('click', this.onToggleAccordion))
  }

  onToggleAccordion(e) {
    const { currentTarget } = e
    const accordionItem = currentTarget.parentNode
    accordionItem.classList.toggle('--active')
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { accordions } = this.DOM
    accordions.length > 0 && accordions.forEach(accordion => accordion.querySelector('.question__title').removeEventListener('click', this.onToggleAccordion))
  }

  initCategoryFilters() {
    for (let categoryLink of this.DOM.categories){
      categoryLink.addEventListener('click', this.onCategoryFilterClick)
    }
  }

  onCategoryFilterClick(e){
    e.preventDefault();
    const {currentTarget} = e;
    const {category} = currentTarget.dataset;

    this.DOM.categories.forEach(link => link.parentNode.classList.remove('--active'))
    currentTarget.parentNode.classList.add('--active')
    this.DOM.accordions.forEach((question, index) => {
      if (!category || (question.dataset.category === category)){
        question.classList.remove('--hidden')
        gsap.from(question, { opacity: 0, y: 40, delay: index * 0.03 })
      } else {
        question.classList.add('--hidden')
      }
    })

    ScrollTrigger.refresh()
    
  }
}
