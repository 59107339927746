import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Marquee {

    constructor(container) {

        this.DOM = { container: container }
    
        this.init()
    }

    init() {

      if (isDEVMODE) console.log('Init Marquee')
      const { container } = this.DOM
      this.TL = gsap.to(container, {
        scrollTrigger: {
            trigger: container,
            scrub: true,
            top: 'top center'
        },
        x: () => - (container.offsetWidth - container.parentNode.offsetWidth)
      });

    }

    destroy() {

      if (isDEVMODE) console.log('Destroy Marquee')
      this.TL.kill()

    }

}