import { isDEVMODE } from '../_globals'


export default class WknHelperGrid {
  constructor() {
    this.init()
  }

  init() {
    this.addEvents()
  }

  addEvents() {
    this.toggleGrid = this.toggleGrid.bind(this)
    window.addEventListener('keydown', this.toggleGrid)
  }

  toggleGrid(e) {
    if (e.code === 'KeyG' && e.altKey) document.body.classList.toggle('show-grid')
  }

  destroy() {
    window.removeEventListener('keyup', this.toggleGrid)
    document.body.classList.remove('--showing')
  }
}
