import { domStorage } from "../_globals"
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CustomEase)

export default class Footer {
  constructor() {
    this.DOM = { el: domStorage.footer }
    this.DOM.logoBowl = this.DOM.el.querySelector('.Footer__bowl')
    this.bowlAnimation()
  }

  bowlAnimation() {
    const { logoBowl } = this.DOM

    this.logoST = ScrollTrigger.create({
      trigger: logoBowl,
      onEnter: () => {
        this.logoTL.invalidate()
        this.logoTL.restart()
      }
    })

    const ease = CustomEase.create("custom", "M0,0 C0.226,0.58 0.122,1 0.368,1 0.726,1 0.428,0 1,0 ")
    
    this.logoTL = gsap.timeline({ 
      paused: true, 
      defaults: { ease, duration: 1.3 }
    })

    this.logoTL.to(logoBowl, {yPercent: () => -20, duration: 1.5 }, 0);
    this.logoTL.to(logoBowl.querySelectorAll('.leroux > path'), { 
      yPercent: (index) => gsap.utils.random(-50, -80, 1),
      xPercent: (index) => gsap.utils.random(-10, 0, 1),
      rotate: (index) => gsap.utils.random(-25, 25, 1), 
      scale: (index) => gsap.utils.random(0.8, 1, 0.1), 
      stagger: { amount: 0.1, from: 'random', axis: "x" }}, 0);
  }
}