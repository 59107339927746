import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Anchors from '../modules/anchors'
import WknPlyr from '../modules/wkn-plyr'
import WknSwiper from '../modules/wkn-swiper'

export default class PageComponents extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    this.anchors = new Anchors(viewStorage.current.querySelector('.Anchors__nav'))
    this.swiper = new WknSwiper(viewStorage.current.querySelector('.swiper'))
    this.plyr = new WknPlyr(viewStorage.current.querySelector('.plyr__container'))
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    this.swiper.destroy()
    this.anchors.destroy()
    this.plyr.destroy()
  }
}
