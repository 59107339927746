import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import WknSwiper from '../modules/wkn-swiper';
gsap.registerPlugin(DrawSVGPlugin)

export default class Fabrication extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      swiperFabricationSection : viewStorage.current.querySelector('.Tabs__slider'),
      swiperFabrication : viewStorage.current.querySelector('.Tabs__slider .swiper.--content'),
      swiperTitleWords : viewStorage.current.querySelectorAll('.Tabs__slider .lines > span'),
      swiperNav : viewStorage.current.querySelectorAll('.Tabs__slider .Slider__nav li'),
      swiperNavTimers : viewStorage.current.querySelectorAll('.Tabs__slider .Slider__nav li .timer'),
      swiperProducts : viewStorage.current.querySelector('.block-products__slider .swiper'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Fabrication`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperFabrication, swiperProducts } = this.DOM
    swiperFabrication && this.initSwiperFabrication()
    swiperProducts && this.initSwiperProducts()
  }

  initSwiperFabrication() {

    const { swiperFabricationSection, swiperFabrication, swiperTitleWords, swiperNav, swiperNavTimers } = this.DOM

    this.swiperFabricationTimer = 10
    this.swiperFabrication = new WknSwiper(swiperFabrication, {
      slidesPerView: 1,
      spaceBetween: 20,
      virtualTranslate: true,
      disableOnInteraction: true,
    })

    // SetupTimers
    swiperNavTimers.forEach((timer, index) => {
      timer.TL = gsap.timeline({
        paused: true,
        onComplete: () => {
          const { isEnd } = this.swiperFabrication.swiper
          this.swiperFabrication.swiper.slideTo(isEnd ? 0 : index + 1)
        }
      })
      timer.TL.fromTo(timer.querySelector('svg circle+circle'), { drawSVG:"0%" }, { drawSVG:"100%", ease: 'ease.none', duration: this.swiperFabricationTimer })
    })


    this.currentTimer = swiperNavTimers[0]
    this.swiperFabrication.swiper.on('slideChange', () => {

      // Update index
      this.currentIndex = this.swiperFabrication.swiper.activeIndex
      this.oldTimer = this.currentTimer ? this.currentTimer : null
      this.currentTimer = swiperNavTimers[this.currentIndex]

      // Update nav
      swiperNav.forEach(item => item.classList.remove('--active'))
      swiperNav[this.currentIndex].classList.add('--active')
      this.oldTimer && this.oldTimer.TL.pause()
      this.currentTimer && this.currentTimer.TL.restart()

      // Update words
      swiperTitleWords.forEach(item => item.classList.remove('--active'))
      swiperTitleWords[this.currentIndex].classList.add('--active')

    })

    swiperNav.forEach(element => {
      element.addEventListener('click', (e) => {
        const index = [... swiperNav].indexOf(e.currentTarget)
        this.swiperFabrication.swiper.slideTo(index)
      })
    })

    if (this.currentTimer?.TL) {
      this.swiperFabricationST = ScrollTrigger.create({
        trigger: swiperFabricationSection,
        onEnterBack: () => this.currentTimer && this.currentTimer.TL.pause(),
        onEnter: () => this.currentTimer && this.currentTimer.TL.restart(),
        onLeave: () => this.currentTimer && this.currentTimer.TL.pause(),
        onLeaveBack: () => this.currentTimer && this.currentTimer.TL.resume()
      });
    }
  }

  initSwiperProducts() {
    const { swiperProducts } = this.DOM
    this.swiperProducts = new WknSwiper(swiperProducts, {
      slidesPerView: 'auto',
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        }
      }
    })
  }

  // onEnterCompleted() {}

  onLeave() {
    this.swiperFabricationST && this.swiperFabricationST.kill(true)
  }

  onLeaveCompleted() {
    const { swiperFabrication, swiperProducts } = this
    const { swiperNavTimers } = this.DOM
    swiperFabrication && swiperFabrication.destroy()
    swiperNavTimers.forEach(timer => timer.TL.kill(true))
    swiperProducts && swiperProducts.destroy()

  }
}
