import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals';
import WknSwiper from '../modules/wkn-swiper';

export default class ChicoreePlant extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      swiperFabrication : viewStorage.current.querySelector('.Tabs__slider .swiper'),
      swiperProducts : viewStorage.current.querySelector('.block-products__slider .swiper'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Plante`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperFabrication, swiperProducts } = this.DOM
    swiperProducts && this.initSwiperProducts()
    swiperFabrication && this.initSwiperFabrication()
  }

  initSwiperProducts() {

    const { swiperProducts } = this.DOM
    this.swiperProducts = new WknSwiper(swiperProducts, {
      slidesPerView: 'auto',
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        }
      }
    })
  }

  initSwiperFabrication() {
    swiperNav.forEach(element => {
      element.addEventListener('click', (e) => {
        const index = [... swiperNav].indexOf(e.currentTarget)
        this.swiperFabrication.swiper.slideTo(index)
      })
    });
  }


  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { swiperFabrication, swiperProducts } = this
    swiperFabrication && swiperFabrication.destroy()
    swiperProducts && swiperProducts.destroy()
  }
}
