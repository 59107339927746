import {isDEVMODE, viewStorage} from '../_globals'

export default class Quantity {

  constructor(nav) {
    this.DOM = {
      tabsNav: nav
    }

    this.init()

  }

  init() {

    if (isDEVMODE) console.log('Init Quantity')
    jQuery("body").on( 'updated_wc_div', () => {
      this.bindEvents()
    });
  }

  bindEvents() {
    if (isDEVMODE) console.log('Quantity: Binding events')

    const buttons = viewStorage.current.querySelectorAll('.quantity__btn')
    for (let button of buttons){
      button.addEventListener('click', this.onClick)
    }
  }

  removeEvents() {
    if (isDEVMODE) console.log('Quantity: Removing events')

    const buttons = viewStorage.current.querySelectorAll('.quantity__btn')
    for (let button of buttons){
      button.removeEventListener('click', this.onClick);
    }
  }

  onClick(e){
    e.stopImmediatePropagation()
    const {targetId} = e.currentTarget.dataset;
    const input = viewStorage.current.querySelector(`#${targetId}`);
    if (input){
      const previousValue = input.value;
      const intValue = parseInt(input.value);
      const oldValue = isNaN(intValue) ? 0 : intValue;
      if (e.currentTarget.classList.contains('--min')) input.value = Math.max(oldValue - 1, 1);
      else if (e.currentTarget.classList.contains('--add')) input.value = Math.min(oldValue + 1, 100);
      input.dispatchEvent(new Event('change'));
      input.dispatchEvent(new Event('keyup'));
      input.dispatchEvent(new Event('keydown'));

      if (previousValue != input.value){
        const updated_cart_buttons = viewStorage.current.querySelectorAll('.woocommerce-cart-form [name="update_cart"]')
        for (let button of updated_cart_buttons){
          button.disabled = false;
          button.ariaDisabled = false;
        }
      }
    }
  }
}
