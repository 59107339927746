import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals';
import WknSwiper from '../modules/wkn-swiper';

export default class About extends Renderer {

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    
    this.DOM = {
      swiperNews : viewStorage.current.querySelector('.News__slider .swiper'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page A propos`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperNews } = this.DOM
    swiperNews && this.initSwiperNews()
  }

  initSwiperNews() {
    const { swiperNews } = this.DOM
    this.swiperNews = new WknSwiper(swiperNews, { 
      slidesPerView: 'auto',
      spaceBetween: 35,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 2.2,
        }
      }
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { swiperNews } = this
    swiperNews && swiperNews.destroy()
    
  }
}
