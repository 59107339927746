import { isDEVMODE, domStorage } from '../_globals'
import gsap from 'gsap'

export default class Woocommerce {

  constructor() {
    this.addEvents()
    this.addedProduct = {}
  }

  addEvents() {

    if (isDEVMODE) console.log('Init Woocommerce', this.addedProduct)
    const { cartCount, cartNotif } = domStorage
    const cartNotifProduct = cartNotif.querySelector('span')
    cartNotifProduct.innerHTML = ''

    jQuery(document.body).on('adding_to_cart',  (event, fragments, cart_hash) => {
      isDEVMODE && console.log('adding_to_cart')

      document.body.classList.add('--adding-to-cart')
      this.addedProduct.title = fragments[0].parentNode.querySelector('h3').textContent
    })

    jQuery( document.body ).on( 'added_to_cart', (event, fragments, cart_hash) => {
      isDEVMODE && console.log('added_to_cart')

      document.body.classList.remove('--adding-to-cart')
      document.body.classList.add('--added-to-cart')

      gsap
      .timeline({
        defaults: { duration: 1.2, ease: 'power4.inOut', clearProps: 'transform' },
        onStart : () => {
          cartNotifProduct.innerHTML = this.addedProduct.title
        },
        onComplete: () => {
          this.addedProduct = {}
          cartNotifProduct.innerHTML = ''
          document.body.classList.remove('--added-to-cart')
        }
      })
      .fromTo(cartNotif, { yPercent: 200 }, { yPercent: 1 })
      .to(cartNotif, { yPercent: 200 }, '+=2')
      
    });


  }
}
