import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Anchors from '../modules/anchors'


export default class Entreprise extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      historyIntro : viewStorage.current.querySelector('.history__intro'),
      history : viewStorage.current.querySelectorAll('.history__slides'),
      historyLast : viewStorage.current.querySelectorAll('.history__last .flex'),
      navBar : viewStorage.current.querySelector('.history__navbar'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Entreprise`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { history, historyLast, navBar } = this.DOM
    history && this.initHistory()
    historyLast && this.initHistoryLast()
    navBar && this.initNavBar()

    this.anchors = new Anchors(viewStorage.current.querySelector('.history__navbar'))

  }

  initHistory() {
    const { history, navBar } = this.DOM

    const links = navBar.querySelectorAll('.link')
    console.log(links)

    let mm = gsap.matchMedia();

    history.forEach((date,index) => {

      const odd = index % 2  == 0;

      date.ST = ScrollTrigger.create({
        trigger: date,
        start: "top bottom-=10%",
        end: "bottom bottom-=10%",
        onEnterBack: () => {
          links.forEach(link => link.classList.remove('--active'))
          links[index].classList.add('--active')
        },
        onEnter: () => {
          links.forEach(link => link.classList.remove('--active'))
          links[index].classList.add('--active')
        }
      })

      date.TL = gsap.timeline({ 
        paused: true, 
        defaults: { duration: 1, ease:'power0.none' },
        scrollTrigger : {
          trigger: date,
          start: 'top bottom',
          end: 'top top',
          scrub: true
        }
      })

      date.TLVisual = gsap.timeline({ 
        paused: true, 
        defaults: { duration: 1, ease:'power0.none' },
        scrollTrigger : {
          trigger: date,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true
        }
      })
  
      date.TL.fromTo(date.querySelector('.history__slides--inner'), { y : () => - window.innerHeight * 0.5 }, { y: 0 }, 0)

      mm.add("(min-width: 768px)", () => {
        date.TLVisual.fromTo(date.querySelector('.history__slides--inner .visual__container'), { y : () => window.innerHeight, rotate : () => odd ? -10 : 10 }, { y : () => - window.innerHeight * 0.5, rotate : () => odd ? 10 : -10 }, 0)
      });
      
      mm.add("(max-width: 767px)", () => {
        date.TLVisual.fromTo(date.querySelector('.history__slides--inner .visual__container'), { y : () => window.innerHeight, rotate : () => odd ? -10 : 10 }, { y : 0, rotate : () => odd ? 10 : -10, duration: 0.3 }, 0)  
      });

    });  
  }

  initHistoryLast() {
    const { historyLast } = this.DOM

    historyLast.forEach((date,index) => {
      const odd = index % 2  == 0;
      date.TLVisual = gsap.timeline({ 
        paused: true, 
        defaults: { duration: 1, ease:'power0.none' },
        scrollTrigger : {
          trigger: date,
          start: 'top bottom',
          end: 'center center',
          scrub: true,
        }
      })

      date.TLVisual.fromTo(date.querySelector('.visual__container'), { y : () => window.innerHeight, rotate : () => odd ? 7 : -7 }, { y : 0, rotate : () => odd ? -7 : 7 }, 0)

    });
  }

  initNavBar() {
    const { historyIntro, historyLast, navBar } = this.DOM

    this.navbarST = ScrollTrigger.create({
      trigger: historyIntro,
      start: "bottom center",
      endTrigger: historyLast,
      end: "top bottom+=20%",
      onEnterBack: () => {
        navBar.classList.add('--visible');
      },
      onEnter: () => {
        navBar.classList.add('--visible');
      },
      onLeave: () => {
        console.log('onLeave')
        navBar.classList.remove('--visible');
      },
      onLeaveBack: () => {
        navBar.classList.remove('--visible');
      }
    });
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { history, historyLast } = this.DOM
    history && history.forEach(date => {
      date.TL.kill()
      date.TLVisual.kill()
    })
    historyLast && historyLast.forEach(date => date.TLVisual.kill())
    this.anchors.destroy()
    this.navbarST.kill()
  }
}
