import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import WknSwiper from '../modules/wkn-swiper'
import { CustomEase } from 'gsap/CustomEase'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Swiper, { Thumbs } from 'swiper'
Swiper.use([Thumbs])
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CustomEase)

export default class scrollTrigger extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.DOM = {
      backLink: viewStorage.current.querySelector('.Btn.--back '),
      gallerySwiper : viewStorage.current.querySelector('article.single-product-details .woocommerce-product-gallery__wrapper .swiper'),
      galleryThumbs : viewStorage.current.querySelector('article.single-product-details .woocommerce-product-gallery__wrapper .swiper-thumbs'),
      marquee : viewStorage.current.querySelector('article.single-product-details .marquee'),
      details : viewStorage.current.querySelector('article.single-product-details'),
      detailsHeadline: viewStorage.current.querySelector('article.single-product-details .entry-headline'),
      detailsText : viewStorage.current.querySelector('article.single-product-details .entry-text'),
      visualContainer : viewStorage.current.querySelector('.entry-images'),
      visual : viewStorage.current.querySelector('.entry-images .wp-post-image'),
      accordions : viewStorage.current.querySelectorAll('.ProductB2B__single__description .content'),

      // swiperProducts : viewStorage.current.querySelector('.block-products__slider .swiper')
    }

    this.isB2B = this.DOM.details.classList.contains('product_cat-produit-industriel')

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Product B2B`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { accordions } = this.DOM
    accordions.length > 0 && this.initAccordion()

    const { isB2B } = this
    isB2B && this.initGallery()
    !isB2B && this.initProductAnimation()

  }

  initAccordion() {
    const { accordions } = this.DOM
    this.onToggleAccordion = this.onToggleAccordion.bind(this)
    accordions.forEach(accordion => accordion.querySelector('.description__title').addEventListener('click', this.onToggleAccordion))
  }

  onToggleAccordion(e) {
    const { currentTarget } = e
    const accordionItem = currentTarget.parentNode
    accordionItem.classList.toggle('--active')
  }

  initGallery() {
    const { gallerySwiper, galleryThumbs } = this.DOM

    this.galleryThumbs = new Swiper(galleryThumbs, {
      slidesPerView : 'auto',
      spaceBetween: 20,
      centerInsufficientSlides: true,
      watchSlidesProgress: true,
      breakpoints: {
        768 : {
          centerInsufficientSlides: false,
          direction: "vertical",
        }
      }
    })

    this.gallerySwiper = new Swiper(gallerySwiper, {
      thumbs: {
        swiper: this.galleryThumbs,
      },
      spaceBetween: 20,
    })

  }

  initProductAnimation() {
    const { details, backLink, marquee, detailsHeadline, detailsText, visualContainer, visual } = this.DOM

    this.mm = gsap.matchMedia();

    this.productEntrance = gsap.timeline({
      defaults : { ease: 'power4.out', duration: 1.2 }
    })

    this.productEntrance.from(visual, { y: -window.innerHeight, rotate: 20, ease: 'expo.out', duration: 2.4 }, 0)
    this.productEntrance.from(marquee, { yPercent: -100, opacity: 0 }, '-=1.6')
    this.productEntrance.from(backLink, { xPercent: 10, opacity: 0 }, '-=1.2')
    this.productEntrance.from(detailsHeadline, { xPercent: -10, opacity: 0 }, '-=1.2')
    this.productEntrance.from(detailsText, { xPercent: 10, opacity: 0 }, "-=1")

    this.mm.add("(min-width: 768px)", () => {

      this.productTL = gsap.timeline({
        paused: true,
        scrollTrigger : {
          trigger: details,
          scrub: true,
          start : 'top top',
          end : 'bottom bottom',
          onEnter: () => console.log('onEnter'),
          onLeave: () => console.log('onLeave'),
          onLeaveBack: () => console.log('onLeaveBack')
        }
      })

      this.productTL.to(visualContainer, { y : () => details.offsetHeight - window.innerHeight, ease : 'power1.inOut' }, 0)

    });
  }

  // initSwiperProducts() {

  //   const { swiperProducts } = this.DOM
  //   this.swiperProducts = new WknSwiper(swiperProducts, { 
  //     slidesPerView: 'auto',
  //     spaceBetween: 20,
  //     breakpoints: {
  //       768: {
  //         slidesPerView: 3,
  //       },
  //       992: {
  //         slidesPerView: 4,
  //       }
  //     }
  //   })
  // }

  // onEnterCompleted() {}

  onLeave() {
    this.productEntrance && this.productEntrance.kill(true)
    this.productTL && this.productTL.kill(true)
    this.gallerySwiper && this.gallerySwiper.destroy()
    this.galleryThumbs && this.galleryThumbs.destroy()
    this.swiperProducts && this.swiperProducts.destroy()

    const { accordions } = this.DOM
    accordions.length > 0 && accordions.forEach(accordion => accordion.querySelector('.description__title').removeEventListener('click', this.onToggleAccordion))
  }

  // onLeaveCompleted() {
  // }
}
