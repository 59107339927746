/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import PageComponents from './pages/components'
import Homepage from './pages/homepage'
import Entreprise from './pages/entreprise'
import Fabrication from './pages/fabrication'
import Contact from './pages/contact'
import Faq from './pages/faq'
import ChicoreePlant from './pages/plant'
import Pro from './pages/pro'
import About from './pages/about'
import ArchiveBlog from './pages/archiveBlog'
import SingleProduct from './pages/single-product'
import SingleProductB2B from './pages/single-product-b2b'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item):not(.page-numbers):not(.ajax_add_to_cart):not(.wc-forward):not(.checkout-button):not(.wpml-ls-link)',
  renderers: {
    default: Page,
    pageComponents: PageComponents,
    homepage: Homepage,
    entreprise: Entreprise,
    fabrication: Fabrication,
    contact: Contact,
    faq: Faq,
    chicoreeplant: ChicoreePlant,
    about: About,
    proPage: Pro,
    archiveBlog: ArchiveBlog,
    singleProduct: SingleProduct,
    singleProductB2B: SingleProductB2B,
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
