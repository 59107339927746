import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Number from '../modules/number';

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Page Default`)
    if (isDEVMODE) console.log(viewStorage.current)
  }

  // onEnterCompleted() {}

  // onLeave() {}

  // onLeaveCompleted() {
  // }
}
