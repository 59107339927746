/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'
import Socials from './modules/socials'
import Newsletter from './modules/newsletter'
import Quantity from "./modules/quantity";
import {FormsManager} from "@wokine-agency/forms";
import Manager from "@wokine-agency/forms/src/Manager";

const quantityModule = new Quantity();

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  const { body } = domStorage
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)
  viewStorage.social = new Socials(body)
  viewStorage.newsletter = new Newsletter(body)


  quantityModule.bindEvents();

  // Anchor
  if (window.location.hash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  } else {
    setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)
  }

  // Intro
  GlobalIntro()

  // Add Classs to body
  if (viewStorage.current.classList.contains('--filiere-francaise')) body.classList.add('--filiere-francaise')
  if (viewStorage.current.classList.contains('Entreprise__page')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('Fabrication')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('Pro')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('--nos-actions-rse')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('--shop-b2b')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('ProductB2B__single')) body.classList.add('--logo-b2b')

  if (viewStorage.current.classList.contains('Cart') || viewStorage.current.classList.contains('Checkout') || viewStorage.current.classList.contains('Account')) {
    document.querySelector('.Chatbot__toggle').click()
  }
  
  initForms({
    DOM: domStorage.body
  })

}

export const onEnter = (to, trigger) => {
  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = ''

  if (hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()
  viewStorage.social = new Socials()
  quantityModule.bindEvents();

  ScrollTrigger.refresh()

  // Add Classs to body
  if (viewStorage.current.classList.contains('--filiere-francaise')) body.classList.add('--filiere-francaise')
  if (viewStorage.current.classList.contains('Entreprise__page')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('Fabrication')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('Pro')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('--nos-actions-rse')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('--shop-b2b')) body.classList.add('--logo-b2b')
  if (viewStorage.current.classList.contains('ProductB2B__single')) body.classList.add('--logo-b2b')

  initForms({
    DOM: viewStorage.current
  })

}

export const onEnterCompleted = (to, from, trigger) => {

  if (isDEVMODE) console.log('onEnterCompleted')

  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

  // Btn Back
  /* const backLinks = viewStorage.current.querySelectorAll('.Btn.--back ');
  backLinks.forEach(btn => btn.href = from.page.URL) */

}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasSocials, hasModals, hasCursor } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasSticky && viewStorage.viewSticky.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasModals && viewStorage.viewModals.destroy()
  hasSocials && viewStorage.social.destroy()
  hasCursor && globalStorage.Cursor.removeHovers()
  quantityModule.removeEvents();

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  const { menu } = domStorage
  const { menuOpen, submenuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()
  submenuOpen && globalStorage.closeSubMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

  // Remove Classs to body
  if (viewStorage.current.classList.contains('--filiere-francaise')) body.classList.remove('--filiere-francaise')
  if (viewStorage.current.classList.contains('Entreprise__page')) body.classList.remove('--logo-b2b')
  if (viewStorage.current.classList.contains('Fabrication')) body.classList.remove('--logo-b2b')
  if (viewStorage.current.classList.contains('Pro')) body.classList.remove('--logo-b2b')
  if (viewStorage.current.classList.contains('--nos-actions-rse')) body.classList.remove('--logo-b2b')
  if (viewStorage.current.classList.contains('--shop-b2b')) body.classList.remove('--logo-b2b')
  if (viewStorage.current.classList.contains('ProductB2B__single')) body.classList.remove('--logo-b2b')

  FormsManager.destroy({
    DOM: viewStorage.current
  });
}

function initForms({DOM}){

  const onStatusChange = (form, statut) => {

    form.getFieldset('particuliers').getElement().style.display = (statut === 'Particulier') ? 'block' : 'none'
    for (let fiedEl of form.requiredParticuliersFields){
      fiedEl.required = (!statut || statut === 'Particulier')
    }

    form.getFieldset('professionnels').getElement().style.display = (statut === 'Professionnel') ? 'block' : 'none'
    for (let fiedEl of form.requiredProfessionnelsFields){
      fiedEl.required = (!statut || statut === 'Professionnel')
    }
  }

  const onSubjectChange = (form, subject) => {
    form.getFieldset('produit_infos').getElement().style.display = (subject === 'Réclamation de produit') ? 'block' : 'none';
    for (let fiedEl of form.requiredProductFields){
      fiedEl.required = (!subject || subject === 'Réclamation de produit')
    }
  }


  FormsManager.init({
    debug: false,
    DOM,
    clearOnSuccess: !isDEVMODE,
    forms: {
      contact:{
        events: {
          init: (e) => {

            const form = e.detail.form;

            form.requiredParticuliersFields = [...Object.values(form.getFieldset('particuliers').getFields())]
                .flatMap((field) => field.getElementsAsArray())
                .filter(field => field.required)

            form.requiredProfessionnelsFields = [...Object.values(form.getFieldset('professionnels').getFields())]
                .flatMap((field) => field.getElementsAsArray())
                .filter(field => field.required)

            form.requiredProductFields = [...Object.values(form.getFieldset('produit_infos').getFields())]
                .flatMap((field) => field.getElementsAsArray())
                .filter(field => field.required)

            onStatusChange(e.detail.form, e.detail.form.getField('statut').getValue())
            onSubjectChange(e.detail.form, e.detail.form.getField('sujet').getValue())
          },
          onSuccess: () => {
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 1000)
          }
        },
        fields: {
          statut: {
            events: {
              change: (e) => {
                const form = e.detail.field.getForm();
                const statut = e.target.value;
                onStatusChange(form, statut)
              }
            }
          },
          sujet: {
            events: {
              change: (e) => {
                const form = e.detail.field.getForm();
                const subject = e.target.value;
                onSubjectChange(form, subject)
              }
            }
          }
        }
      }
    }
  });
}

