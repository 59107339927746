import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'

export default class viewModals {

  constructor() {
    this.DOM = {
      links: viewStorage.current.querySelectorAll('[data-modal]')
    }

    if (this.DOM.links.length === 0) return

    viewStorage.hasModals = true

    this.init()
  }

  init() {
    if (isDEVMODE) console.log('Init pageModals')

    this.addEvents()
  }

  addEvents() {
    const { links } = this.DOM

    this.createPanel = this.createPanel.bind(this)
    links.forEach(link => link.addEventListener('click', this.createPanel));

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.destroyPanel = this.destroyPanel.bind(this)
  }

  createPanel(e) {
    e.preventDefault()

    const { body, overlay } = domStorage
    const { href } = e.currentTarget

    body.classList.add('showModal')
    body.classList.add('--loading')
    body.style.overflow = 'hidden';

    this.previousUrl = window.location.href


    fetch(href)
      .then((req) => req.text())
      .then(data => {

        body.classList.remove('--loading')
        window.history.pushState('', '', href)

        const parser = new DOMParser()
        const doc = parser.parseFromString(data, 'text/html')
        console.log(doc)
        const singleDOM = doc.querySelector('[data-taxi-view]').parentNode
        const singleClass = singleDOM.querySelector('[data-taxi-view]').dataset.routerView

        const panelTemplate = `
        <div id="Modal" class="Modal__panel --${singleClass}">
          <div class="Modal__wrapper" data-lenis-prevent>
            <div class="Modal__content">
              <button class="Close" aria-label="Fermer">
                <i class="Icon" data-name="close">
                  <span class="sr-only">Fermer</span>
                    <svg width="30" height="30" viewBox="0 0 29.12 29.12">
                      <line x1="1.06" y1="1.06" x2="28.06" y2="28.06"/>
                      <line x1="28.06" y1="1.06" x2="1.06" y2="28.06"/>
                    </svg>
                  </span>
                </i>
              </button>
              ${singleDOM.innerHTML}
            </div>
          </div>
        </div>
        `
        domStorage.body.insertAdjacentHTML('beforeend', panelTemplate)

        this.DOM.panel = document.getElementById('Modal')
        this.DOM.panelCloseBtn = this.DOM.panel.querySelector('.Close')
        this.DOM.panelCloseBtn.addEventListener('click', this.close)
        overlay.addEventListener('click', this.close)

        this.open()

      })
      .catch(error => {
        body.classList.remove('--loading')
        body.classList.remove('showModal')
        body.style.overflow = ''
        console.log(`Il y a eu un problème avec l'opération fetch: ${error.message}`);
      });
  }

  open() {
    if (isDEVMODE) console.log('Open Modal Panel')

    // Animation
    this.isOpen = true
    const { panel } = this.DOM
    gsap.from(panel, { yPercent: 100, ease: 'power3.inOut', duration: 1 })
  }

  close() {
    if (isDEVMODE) console.log('Close Modal Panel')

    // Destroy after animation
    const { body } = domStorage
    const { panel } = this.DOM
    gsap.to(panel, { yPercent: 100, ease: 'power3.inOut', duration: 1, onComplete: () => this.destroyPanel() })

    if (this.previousUrl){
      window.history.pushState('', '', this.previousUrl)
    }

    setTimeout(() => {
      this.isOpen = false
      body.classList.remove('showModal')
      body.style.overflow = ''
    }, 300)

  }

  destroyPanel() {
    const { panel, panelCloseBtn } = this.DOM
    panelCloseBtn.removeEventListener('click', this.close)
    domStorage.body.removeChild(panel)
  }

  destroy() {
    const { links } = this.DOM
    links.forEach(link => link.removeEventListener('click', this.createPanel));
    viewStorage.hasModals = false
    this.isOpen && this.close()
  }
}
