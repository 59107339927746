import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'

export default class Newsletter {

  constructor(container = viewStorage.current) {

    this.DOM = { container: container.querySelector('section.social') }

    if (this.DOM.container) {
      this.DOM.visuals = this.DOM.container.querySelectorAll('.visual__container')
      this.init()
      viewStorage.hasSocials = true
    }

  }

  init() {

    if (isDEVMODE) console.log('Init Socials')


    const { container, visuals } = this.DOM


    this.TL = gsap.timeline({ 
      paused: true, 
      defaults: { ease: 'power4.out', duration: 1.2 },
      scrollTrigger : {
        trigger: container,
        start: 'top bottom-=35%',
        end: 'bottom top',
        onEnter: () => this.TL.invalidate().timeScale(1).restart(),
        onLeaveBack: () => this.TL.timeScale(3).reverse(),
      }
    })

    this.TL.fromTo(visuals, { y : () => window.innerHeight * 0.8, rotate: 0 }, { y: 0, rotate: (index) => gsap.utils.random(-20, 20), stagger: 0.05 })

  }

  destroy() {

    if (isDEVMODE) console.log('Destroy Socials')
    this.TL.kill()
    viewStorage.hasSocials = false

  }
}
