import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import Form from "./form";

export default class Newsletter {

  constructor(container = viewStorage.current) {

    this.DOM = {
      container: container.querySelector('section.newsletter'),
      form: viewStorage.current.querySelector('section.newsletter form')
    }

    if (this.DOM.container) {
      this.DOM.visuals = this.DOM.container.querySelectorAll('.newsletter .newsletter__visuals .visual__container')
      this.init()
      viewStorage.hasNewsletter = true
    }
  }

  init() {

    if (isDEVMODE) console.log('Init Newsletter')

    //Apparition newsletter
    const { container, visuals } = this.DOM

    this.TL = gsap.timeline({
      paused: true,
      defaults: { ease: 'expo.out', duration: 1.6 },
      scrollTrigger : {
        trigger: container,
        onEnter: () => this.TL.invalidate().restart(),
        onEnterBack: () => this.TL.invalidate().restart(),
      }
    })

    this.TL.fromTo(visuals,
      { y : () => gsap.utils.random([-container.offsetHeight, container.offsetHeight]), rotate: (index) => gsap.utils.random(-20, 20) },
      { y : () => gsap.utils.random(container.offsetHeight * 0.5, 1), rotate: (index) => gsap.utils.random(-20, 20), stagger: 0.05 })

  }

  destroy() {

    if (isDEVMODE) console.log('Destroy Newsletter')
    this.TL.kill()
    viewStorage.hasNewsletter = false

  }
}
