import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { body } = domStorage
  const { current } = viewStorage

  const pageHero = current.querySelector('.hero')
  const pageHeader = current.querySelector('.Page__header')
  const pageHeaderIcon = pageHeader ? pageHeader.querySelector('.icon') : null
  const pageTitle = pageHeader ? pageHeader.querySelector('h1') : null
  const pageSubtitle = pageHeader ? pageHeader.querySelector('.subtitle > span') : null
  const pageAnchors = pageHeader ? pageHeader.querySelector('.Anchors__nav') : null
  // const pageSections = [...current.children]
  // const allSectionsExceptTheFirst = pageSections.slice(1)
  const pageTitleLines = new SplitText(pageTitle, { type: 'lines' })
  const pageTitleLinesC = new SplitText(pageTitle, { type: 'lines', linesClass: '--oh' })

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power4.out', duration: 0.8 }
    })
    
    pageHero && tl.fromTo(pageHero.querySelector('.cup .visual__container'), { yPercent: 100, scale: 0.9 }, { yPercent: 0, duration: 2, scale: 1 }, 0)
    pageHero && tl.fromTo(pageHero.querySelector('h1'), { y: 80, opacity: 0 }, { y: 0, opacity: 1, duration: 2 }, 0.6)
    pageSubtitle && tl.fromTo(pageSubtitle, { yPercent: 100 }, { yPercent: 0 }, 0)
    pageTitleLines.lines.length && tl.fromTo(pageTitleLines.lines, { yPercent: 120 }, { yPercent: 0, stagger: 0.1 }, pageSubtitle ? '-=0.7' : 0)
    pageHeaderIcon && tl.fromTo(pageHeaderIcon, { opacity: 0, yPercent: 100, rotate: 10 }, { opacity: 1, yPercent: 0, rotate: 0 }, 0)
    pageAnchors && tl.fromTo(pageAnchors, { opacity: 0 }, { opacity: 1 }, '-=0.4')
    // allSectionsExceptTheFirst.length && tl.fromTo(allSectionsExceptTheFirst, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.4')

  return tl
}
