import { isDEVMODE, domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    const { loader } = domStorage

    gsap.timeline({
      onComplete: () => {
        domStorage.body.classList.remove('--animating')
        done()
      }
    })
    .fromTo(loader, { opacity: 1 }, { opacity: 0 }, 0)
    .add(pageIntro(0), 0)
  }

  onLeave({ from, trigger, done }) {
    const { loader } = domStorage
    gsap
      .timeline({
        defaults: { duration: 0.4, ease: 'power4.in' },
        onComplete: () => done()
      })
      .fromTo(loader, { opacity: 0 }, { opacity: 1 }, 0)
  }
}
