import { globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const { header, body } = domStorage
  const { hasSmoothScroll, viewScroll } = viewStorage

  gsap
    .timeline({
      delay: 0.2,
      defaults: { duration: 1.2, ease: 'power4.out' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')

        // Stop Scroll
        if (hasSmoothScroll) viewScroll.scroll.stop()
      },
      onComplete: () => {
        body.classList.remove('--animating')
        body.classList.remove('--intro')

        // Start Scroll
        if (hasSmoothScroll) viewScroll.scroll.start()

        // Cleaning styles
        gsap.set(header, { clearProps: 'all' })

        globalStorage.firstLoad = false
      }
    })
    
    .from(header, { yPercent : -100 }, 0.8)
    .add(pageIntro(), 0)
}
