import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import { Observer } from 'gsap/Observer'
import WknSwiper from '../modules/wkn-swiper';
import gsap from 'gsap'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.headerContainer = this.DOM.el.querySelector('.container')
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.navLinks = this.DOM.menuContainer.querySelectorAll('.Nav__link.--hasSubmenu')
    this.DOM.subMenusWrapper = domStorage.body.querySelector('.Nav__submenus__wrapper')
    this.DOM.subMenus = this.DOM.subMenusWrapper.querySelectorAll('.Nav__submenus__item')
    this.DOM.list = this.DOM.subMenusWrapper.querySelector('.--products ul')
    this.DOM.subMenuSwiperProducts = this.DOM.subMenusWrapper.querySelector('.ProductsSwiper .swiper')
    this.DOM.subMenuSwiperEngagements = this.DOM.subMenusWrapper.querySelector('.EngagementsSwiper .swiper')
    this.DOM.subMenusBackLinks = this.DOM.subMenusWrapper.querySelectorAll('.Btn.--back ')
    this.DOM.togglePublic = this.DOM.el.querySelector('.page.--public')
    this.DOM.subMenuPublic = this.DOM.el.querySelector('.taxonomies.--public')
    this.DOM.toggleIndustrial = this.DOM.el.querySelector('.page.--industrial')
    this.DOM.subMenuIndustrial = this.DOM.el.querySelector('.taxonomies.--industrial')

    this.init()
    this.subMenuProducts()
    this.setEvents()
  }

  init() {
    const { subMenuSwiperProducts, subMenuSwiperEngagements, subMenusWrapper } = this.DOM
    this.swiperProducts = new WknSwiper(subMenuSwiperProducts, { 
      slidesPerView: 'auto',
      spaceBetween: 20,
      navigation : {
        prevEl : subMenusWrapper.querySelector('.ProductsSwiper__wrapper .swiper-navigation .prev'),
        nextEl : subMenusWrapper.querySelector('.ProductsSwiper__wrapper .swiper-navigation .next')
      }
    })

    this.swiperEngagements = new WknSwiper(subMenuSwiperEngagements, { 
      slidesPerView: 'auto',
      spaceBetween: 20,
      navigation : {
        prevEl : subMenusWrapper.querySelector('.EngagementsSwiper__wrapper .swiper-navigation .prev'),
        nextEl : subMenusWrapper.querySelector('.EngagementsSwiper__wrapper .swiper-navigation .next')
      }
    })
  }

  subMenuProducts() {
    const { togglePublic, toggleIndustrial, subMenuPublic, subMenuIndustrial, list } = this.DOM
    togglePublic.addEventListener('mouseenter', (e) => {
      subMenuPublic.classList.add('is--open')
      subMenuIndustrial.classList.remove('is--open');
    })

    toggleIndustrial.addEventListener('mouseenter', (e) => {
      subMenuIndustrial.classList.add('is--open')
      subMenuPublic.classList.remove('is--open');
    })

    list.addEventListener('mouseout', (e) => {
      if (!list.contains(e.relatedTarget)) {
        subMenuIndustrial.classList.remove('is--open');
        subMenuPublic.classList.remove('is--open');
      }
    });
  }

  setEvents() {
    const { body, overlay } = domStorage
    const { headerContainer, toggle, navLinks, subMenusBackLinks } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // SubMenus
    this.clickOutsideTimeout = setTimeout(() => {}, 0)
    this.onClickOutside = this.onClickOutside.bind(this)
    this.openSubmenu = this.openSubmenu.bind(this)
    navLinks.forEach(navlink => navlink.addEventListener('click', this.openSubmenu))
    subMenusBackLinks.forEach(link => link.addEventListener('click', () => {
      if (globalStorage.submenuOpen) this.closeSubmenu()
    }))
    globalStorage.closeSubMenu = () => this.closeSubmenu()

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)

    // Observers
    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 50 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 50 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 50,
      onUp: () => {
        if (!headerContainer.classList.contains('--visible')) headerContainer.classList.add('--visible')
      },
      onDown: () => {
        if (headerContainer.classList.contains('--visible')) headerContainer.classList.remove('--visible')
        if (globalStorage.submenuOpen) this.closeSubmenu()
      }
    })

    this.onHoverObserver = Observer.create({
      target: headerContainer,
      type: 'pointer',
      onHover: () => this.onScrollObserver.disable(),
      onHoverEnd: () => this.onScrollObserver.enable()
    })

  }

  open() {
    // if (isDEVMODE) console.log('Open Menu')

    globalStorage.menuOpen = true
    domStorage.body.classList.add('showMenu')
    domStorage.body.style.overflow = 'hidden'

  }

  close() {
    // if (isDEVMODE) console.log('Close Menu')

    globalStorage.menuOpen = false
    domStorage.body.classList.remove('showMenu')
    domStorage.body.style.overflow = ''
  }

  openSubmenu(e) {
    e.preventDefault()

    const { currentTarget } = e
    const { navLinks, subMenus } = this.DOM
    const { submenuOpen } = globalStorage
    const { body } = domStorage

    // Get submenu
    const targetIndex = [...navLinks].indexOf(currentTarget)
    const targetSubmenu = subMenus[targetIndex]

    // Close if same
    if (targetSubmenu === submenuOpen) {
      this.closeSubmenu()
      return
    }

    // Close if prev
    if (submenuOpen) submenuOpen.classList.remove('--open')
    navLinks.forEach(link => link.classList.remove('--open'))

    // Set new
    globalStorage.submenuOpen = targetSubmenu

    // Set open state
    currentTarget.classList.add('--open')
    targetSubmenu.classList.add('--open')
    body.classList.add('showSubmenu')

    this.onScrollObserver.disable()

    // ClickOutside
    clearTimeout(this.clickOutsideTimeout)
    this.clickOutsideTimeout = setTimeout(() => {
      document.addEventListener('click', this.onClickOutside);
    }, 300);
    
  }

  closeSubmenu() {

    // Close current
    const { navLinks } = this.DOM
    const { submenuOpen } = globalStorage
    submenuOpen.classList.remove('--open')
    navLinks.forEach(link => link.classList.remove('--open'))

    const { body } = domStorage
    body.classList.remove('showSubmenu')
    globalStorage.submenuOpen = null

    this.onScrollObserver.enable()

    document.removeEventListener('click', this.onClickOutside);

  }

  onClickOutside(e) {
    const { target } = e;
    if (globalStorage.submenuOpen && (!globalStorage.submenuOpen.contains(target) && !domStorage.header.contains(target))) this.closeSubmenu()
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
